<div class="dialog-container">
    <div class="close-button" (click)="close()"><app-svg-icon name="close"></app-svg-icon></div>
    <div class="title" translate>texts.Payment_Type_Title</div>
    <div class="dialog-text" translate>texts.Payment_Type_Description</div>
    <div class="payment-options-container">
        <div class="payment-option-container" (click)="selectedInstallMentOption = InstallmentOptionEnum.single"
            [ngClass]="{'payment-option-active': selectedInstallMentOption === InstallmentOptionEnum.single}">
            <div class="radio-button"
                [ngClass]="{'radio-active':selectedInstallMentOption === InstallmentOptionEnum.single}">
            </div>
            <div class="option-text-container">
                <div class="payment-option" translate>texts.Payment_Type_Single_Installment_Title</div>
                <div class="payment-option-discount" translate>texts.Payment_Type_Single_Installment_Discount</div>
            </div>
            <app-svg-icon class="sale-icon" name="Sale"></app-svg-icon>
        </div>
        <div class="payment-option-container" (click)="selectedInstallMentOption = InstallmentOptionEnum.multi"
            [ngClass]="{'payment-option-active':selectedInstallMentOption === InstallmentOptionEnum.multi}">
            <div class="radio-button"
                [ngClass]="{'radio-active': selectedInstallMentOption === InstallmentOptionEnum.multi}">
            </div>
            <div class="option-text-container">
                <div class="payment-option" translate>texts.Payment_Type_Monthly_Pay_Title</div>
            </div>
        </div>
    </div>
    <div class="button confirm-button" [ngClass]="{'button-visible': selectedInstallMentOption}"
        (click)="onPaymentOptionConfirmed.emit(selectedInstallMentOption); close()" translate>
        texts.Payment_Type_Confirm_Button</div>
</div>