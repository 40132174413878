import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NgModule } from "@angular/core";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
//TODO move here all of the material modules from the app
@NgModule({
        declarations: [],

        exports: [
                MatTooltipModule,
                MatDialogModule,
                MatButtonModule,
                MatSliderModule,
                MatSlideToggleModule,
                MatButtonToggleModule,
                MatBottomSheetModule,
                MatProgressSpinnerModule,
                MatTableModule,
                MatExpansionModule,
                MatCheckboxModule,
                MatDatepickerModule,
                MatInputModule,
                MatNativeDateModule,
                MatFormFieldModule,
                MatIconModule,
                MatRadioModule
        ],
})
export class MaterialModule { }
