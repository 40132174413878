import { SelectPaymentTypeComponent } from './../../common-dialogs/select-payment-type/select-payment-type.component';
import { UtilityService } from "../../../services/utility.service";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "app/modules/shared/shared.module";
import { SliderConfigModel } from "app/models/slider-config-model";
import { PackagesFlowService } from "app/services/packages-flow.service";
import {
  ExamSubscriptionRequestModel,
  InstallmentOptionEnum,
  PackageDetails,
  PackageRecommendationRequestModel,
  PackageSubscriptionRequestModel,
} from "app/models/package.model";
import { SequenceOptions, TutorSeniorityLevels } from "app/constants/packages";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogNewComponent } from "../../common-dialogs/confirm/confirm-dialog.component";
import { AuthService, PopupService, ToastService } from "app/services";
import { MessageType } from "app/constants";
import { TranslateService } from "@ngx-translate/core";
import { SubscribedDialogComponent } from "app/standalone-components/new-packages/subscribed/subscribed-dialog.component";
import { Observable, from, map, mergeMap, toArray } from "rxjs";

@Component({
  selector: "app-package-cards",
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: "./package-cards.component.html",
  styleUrls: ["./package-cards.component.scss"],
})
export class PackageCardsComponent implements OnInit {
  @Input() selectedTutorLevel: number;
  @Input() selectedLessonPerWeek;
  @Input() isModificationsAllowed: boolean = false;
  @Input() showAlternativePackages: boolean = false;
  @Input() showOnly5HourPackage: boolean = false;

  @Output() onClose = new EventEmitter();
  @Output() onNewPackagePurchase = new EventEmitter();

  @ViewChild("title", { read: ElementRef }) title: ElementRef;

  packageCards: Array<PackageDetails> = [];
  packageSelectedToBuy: PackageSubscriptionRequestModel;
  carouselConfig: SliderConfigModel = {
    page: [
      { size: 1, spacing: 0.01 },
      { size: 1, spacing: 0.01 },
      { size: 1, spacing: 0.01 },
    ],
    showArrows: true,
  };

  tutorTypes = TutorSeniorityLevels;
  lessonsPerWeek = SequenceOptions;
  lessonPerMonth;

  faqTitle: string;
  faqListItems;

  isExamMode: boolean = false;

  constructor(
    public utilityService: UtilityService,
    public dialog: MatDialog,
    private authService: AuthService,
    private toastService: ToastService,
    private packagesFlowService: PackagesFlowService,
    private translationService: TranslateService,
    private popupService: PopupService
  ) { }

  ngOnInit() {
    this.initPackages();
    this.translateFAQ();
    this.utilityService.trackEvent("package", "packageShoved");
  }

  ngAfterViewInit() {
    if (this.utilityService.isBrowser && !this.isModificationsAllowed)
      setTimeout(() => {
        this.title.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 50);
  }

  initPackages() {
    if (
      this.authService.userDetails?.studentDetails?.expectedTutoringDuration ===
      "0" || this.showOnly5HourPackage
    ) {
      this.isExamMode = true
      this.getExamPackages().subscribe((packages) => {
        if (this.showOnly5HourPackage) {
          this.packageCards = packages.filter((examPackage) =>
            examPackage._model.totalLessons === '5'
          );
        } else {
          this.packageCards = packages;
        }
      });
    } else {
      this.getPackages().subscribe((packages) => {

        this.packageCards = packages.sort((a, b) => parseInt(b.durationMonths) - parseInt(a.durationMonths));
      });
    }
  }

  private getExamPackages(): Observable<PackageDetails[]> {
    return this.packagesFlowService
      .getExamPackages(this.selectedTutorLevel.toString())
      .pipe(
        map((resp) => {
          return resp.offers.map((offer) => {
            return {
              durationMonths: offer.durationMonths.toString(),
              costPerHour: offer.lessonPrice.toString(),
              savePerMonth: offer.savingsPerMonth?.toString(),
              packageId: offer.packageId,
              pricePerMonth: offer.pricePerMonth,
              examPackage: offer.examPackage,
              lessonsPerWeek: offer.lessonsPerWeek,
              _model: offer,
              _isExamPackageVersion: true,
            };
          });
        })
      );
  }

  private getPackages(): Observable<PackageDetails[]> {
    let packageCards: Array<PackageDetails> = [];
    if (this.showAlternativePackages) {
      packageCards = [
        {
          durationMonths: "24",
        },
        {
          durationMonths: "20",
        },
        {
          durationMonths: "16",
        },
        {
          durationMonths: "14",
        },
        {
          durationMonths: "12",
        },
        {
          durationMonths: "10",
        },
        {
          durationMonths: "8",
        },
        {
          durationMonths: "5",
        },
        {
          durationMonths: "4",
        }
      ]
    } else {
      packageCards = [
        {
          durationMonths: "9",
        },
        {
          durationMonths: "6",
        },
        {
          durationMonths: "3",
        }
      ]
    }

    // TODO remove this when BE has lessonPerMonth property
    this.lessonPerMonth = +this.selectedLessonPerWeek * 4;

    return from(packageCards).pipe(
      mergeMap((card) => {
        const packageRecommendation: PackageRecommendationRequestModel = {
          durationMonths: card.durationMonths,
          lessonsPerWeek: this.selectedLessonPerWeek,
          tutorCategory: this.selectedTutorLevel.toString(),
          country: this.utilityService.country,
          improvementPurpose: "",
        };
        return this.packagesFlowService
          .getPackagesRecommendation(packageRecommendation)
          .pipe(
            map((resp) => {
              card.costPerHour = resp.lessonPrice.toString();
              card.savePerMonth = resp.savingsPerMonth ? resp.savingsPerMonth.toString() : "";
              card.packageId = resp.packageId;
              card.pricePerMonth = resp.pricePerMonth;
              card.lessonsPerWeek = resp.lessonsPerWeek;
              return card;
            })
          );
      }),
      toArray()
    );
  }

  buyPackage() {
    if (this.authService.isTeacher()) {
      this.toastService.showMsg(
        "texts.Packages_Section_Tutor_Buy",
        MessageType.info,
        { title: "texts.Packages_Section_Tutor_Buy_title" }
      );
      return;
    }
    this.openPaymentTypeDialog();
  }

  openPaymentTypeDialog() {
    if (this.packageSelectedToBuy.examPackage || this.showOnly5HourPackage) {
      this.openDialogConfirm();
    } else {
      let dialogComp = this.popupService.openResponsiveDialog(
        SelectPaymentTypeComponent
      ).componentInstance;
      dialogComp.onPaymentOptionConfirmed.subscribe((selectedPaymentOption) => {
        this.packageSelectedToBuy.installmentOption = selectedPaymentOption;
        this.confirmPackage();
      })
    }
  };

  openDialogConfirm() {
    const dialogComp = this.dialog.open(
      ConfirmDialogNewComponent
    ).componentInstance;
    dialogComp.dialogText = "texts.Packages_Section_Confirm_Text";
    dialogComp.onConfirm.subscribe(() => {
      this.confirmPackage();
    });
  }

  private translateFAQ() {
    this.translationService
      .get([
        "texts.Price_Page_faq_list_title",
        "texts.Package_Tool_FAQ_Item1_Title",
        "texts.Package_Tool_FAQ_Item1",
        "texts.Package_Tool_FAQ_Item2_Title",
        "texts.Package_Tool_FAQ_Item2",
        "texts.Package_Tool_FAQ_Item3_Title",
        "texts.Package_Tool_FAQ_Item3",
        "texts.Package_Tool_FAQ_Item4_Title",
        "texts.Package_Tool_FAQ_Item4",
        "texts.Package_Tool_FAQ_Item5_Title",
        "texts.Package_Tool_FAQ_Item5",
        "texts.Package_Tool_FAQ_Item6_Title",
        "texts.Package_Tool_FAQ_Item6",
        "texts.Package_Tool_FAQ_Item7_Title",
        "texts.Package_Tool_FAQ_Item7",
      ])
      .subscribe((translations) => {
        this.faqTitle = translations["texts.Price_Page_faq_list_title"];

        this.faqListItems = [
          {
            title: translations["texts.Package_Tool_FAQ_Item1_Title"],
            content: translations["texts.Package_Tool_FAQ_Item1"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item2_Title"],
            content: translations["texts.Package_Tool_FAQ_Item2"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item3_Title"],
            content: translations["texts.Package_Tool_FAQ_Item3"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item4_Title"],
            content: translations["texts.Package_Tool_FAQ_Item4"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item5_Title"],
            content: translations["texts.Package_Tool_FAQ_Item5"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item6_Title"],
            content: translations["texts.Package_Tool_FAQ_Item6"],
          },
          {
            title: translations["texts.Package_Tool_FAQ_Item7_Title"],
            content: translations["texts.Package_Tool_FAQ_Item7"],
          },
        ];
      });
  }

  confirmPackage() {
    if (this.packageSelectedToBuy.examPackage) {
      const subscribeData: ExamSubscriptionRequestModel = {
        tutorCategory: this.selectedTutorLevel.toString(),
        installmentOption: InstallmentOptionEnum.single
      }
      this.packagesFlowService
        .subcribeToExamPackage(subscribeData)
        .subscribe({
          next: (response) => {
            this.utilityService.trackEvent("package", "packageBought");
            this.onNewPackagePurchase.emit();
            this.openSubscribedDialog(response.price);
          },
        });
    } else {
      const subscribeData: PackageSubscriptionRequestModel = {
        packageId: this.packageSelectedToBuy.packageId,
        improvementPurpose: "",
        durationMonths: this.packageSelectedToBuy.durationMonths,
        lessonsPerWeek: this.packageSelectedToBuy.lessonsPerWeek.toString(),
        tutorCategory: this.selectedTutorLevel.toString(),
        installmentOption: this.packageSelectedToBuy.installmentOption,
      };
      this.packagesFlowService.subscribeToPackage(subscribeData).subscribe({
        next: (response) => {
          this.utilityService.trackEvent("package", "packageBought");
          this.onNewPackagePurchase.emit();
          this.openSubscribedDialog(response.price);
        },
      });
    }
  }

  private openSubscribedDialog(price: number) {
    const dialog = this.dialog.open(SubscribedDialogComponent);
    dialog.componentInstance.canNavigateToChat = true;
    dialog.componentInstance.showConfirmButton = true;
    this.packagesFlowService
      .getUserPackages(+this.authService.userDetails.id)
      .subscribe();
    this.utilityService.trackEvent(
      "package",
      "package_purchase",
      "package_price",
      "",
      "",
      price
    );
  }
}
