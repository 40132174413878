import { MaterialModule } from './modules/material/material.module';
import { TokenValidationService } from './services/token-validation.service';
import { APP_ID, APP_INITIALIZER, ErrorHandler, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieModule } from 'ngx-cookie';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { CmsModule } from './modules/cms/cms.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { CoreModule } from './modules/core/core.module';
import { LazyLoadImageHooks } from './hooks/lazy-load-image-hooks';
import { AppHttpInterceptor } from './app-http.interceptor';
import { CustomTranslateLoader } from "./loaders/custom-translate-loader";
import { GlobalErrorHandlerService } from "./error-handler/global-error-handler.service";
import { TranslationHelperService } from './services';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from "./reuse-strategy";
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

declare global {
  interface Window { heap: any; }
  const heap: any;
}

const translationFactory = (httpClient: HttpClient, translationHelperService: TranslationHelperService) => {
  return new CustomTranslateLoader(httpClient, translationHelperService);
};

const getGoogleAuth = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  return environment.gtm[window.document.location.hostname.split('.').pop()]?.GTM_auth;
};
const getGoogleId = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  return environment.gtm[window.document.location.hostname.split('.').pop()]?.GTM_id;
};
const getGooglePreview = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  return environment.gtm[window.document.location.hostname.split('.').pop()]?.GTM_preview;
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    MaterialModule,
    CoreModule,
    CookieModule.forRoot(),
    CmsModule,
    GoogleTagManagerModule.forRoot(
      {
        id: getGoogleId(),
        gtm_auth: getGoogleAuth(),
        gtm_preview: getGooglePreview()
      }),
    HttpClientModule,
    LazyLoadImageModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationFactory,
        deps: [/* PLATFORM_ID, */HttpClient, TranslationHelperService]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    })
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: APP_ID,
      useValue: 'bah-customer',
    },
    TokenValidationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (tokenValidationService: TokenValidationService) => { return () => tokenValidationService.validate() },
      deps: [TokenValidationService, HttpClient],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(APP_ID) appId: string) {
    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}