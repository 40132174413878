import { NoDecimalCurrencyFormatterPipe } from './pipes/no-decimal-currency.pipe';
import { RoundDownFormatterPipe } from './pipes/round-down-formatter.pipe';
import { CurrencyFormatterPipe } from './pipes/currency-formatter.pipe';
import { CreditFormatterPipe } from './pipes/credit-formatter.pipe';
import { DeferLoadDirective } from './directives/deferLoad.directive';
import { ReversePipe } from './pipes/reverse.pipe';
import { UnescapePipe } from './pipes/unescape.pipe';
import { CompHostDirective } from './directives/compHost.directive';
import { MaterialModule } from './../material/material.module';
import { DndDirective } from './directives/drag-n-drop.directive';
import { AddComponent } from './add/add.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WrapperCardComponent } from './wrapper-card/wrapper-card.component';
import { ButtonComponent } from './button/button.component';
import { ErrorComponent } from './error/error.component';
import { LinkButtonComponent } from './link-button/link-button.component';
import { ModalComponent } from './modal/modal.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VideoComponent } from './video/video/video.component';
import { AppointmentTrialComponent } from './appointment-trial/appointment-trial.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BindHTMLDirective } from './directives/bindHTML.directive';
import { FormatCurrencyDirective } from './directives/formatCurrency.directive';
import { DialogCoreComponent } from './dialogs/dialog-core/dialog-core.component';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { HearAboutComponent } from './hear-about/hear-about.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { LoginOrRegisterDialogComponent } from './dialogs/login-or-register-dialog/login-or-register-dialog.component';
import { AcceptAppointmentDialogComponent } from './dialogs/accept-appointment-dialog/accept-appointment-dialog.component';
import { DeleteAppointmentDialogComponent } from './dialogs/delete-appointment-dialog/delete-appointment-dialog.component';
import { CancelAppointmentDialogComponent } from './dialogs/cancel-appointment-dialog/cancel-appointment-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { FaqListComponent } from './faq-list/faq-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CarouselComponent } from './carousel/carousel.component';
import { ImageDeferLazyComponent } from './image-defer-lazy/image-defer-lazy.component';
import { PasswordValidatorDirective } from './directives/password-validator.directive';
import { PhoneValidatorDirective } from './directives/phone-validator.directive';
import { CourseSelectionComponent } from './course-selection/course-selection.component';
import { NewSpinnerComponent } from './new-spinner/new-spinner.component';
import { AdviceSectionComponent } from './advice-section/advice-section.component';

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YY',
		monthYearLabel: 'MMM YYYY'
	},
};

@NgModule({
	declarations: [
		WrapperCardComponent,
		AppointmentTrialComponent,
		ButtonComponent,
		ErrorComponent,
		LinkButtonComponent,
		ModalComponent,
		VideoComponent,
		BindHTMLDirective,
		FormatCurrencyDirective,
		DndDirective,
		AddComponent,
		CompHostDirective,
		DialogCoreComponent,
		InfoDialogComponent,
		HearAboutComponent,
		ReversePipe,
		ConfirmDialogComponent,
		LoginOrRegisterDialogComponent,
		AcceptAppointmentDialogComponent,
		DeleteAppointmentDialogComponent,
		CancelAppointmentDialogComponent,
		SpinnerComponent,
		SvgIconComponent,
		CarouselComponent,
		FaqListComponent,
		DeferLoadDirective,
		ImageDeferLazyComponent,
		CarouselComponent,
		NewSpinnerComponent,
		PasswordValidatorDirective,
		PhoneValidatorDirective,
		CourseSelectionComponent,
		NewSpinnerComponent,
		UnescapePipe,
		AdviceSectionComponent,
		CurrencyFormatterPipe,
		CreditFormatterPipe,
		RoundDownFormatterPipe,
		NoDecimalCurrencyFormatterPipe
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		LocalizeRouterModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule,
		NgSelectModule,
		LazyLoadImageModule,
		MaterialModule,
		MatMomentDateModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatExpansionModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		LocalizeRouterModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule,
		NgSelectModule,
		//TippyModule,
		BindHTMLDirective,
		PasswordValidatorDirective,
		PhoneValidatorDirective,
		FormatCurrencyDirective,
		WrapperCardComponent,
		AppointmentTrialComponent,
		ButtonComponent,
		ErrorComponent,
		LinkButtonComponent,
		ModalComponent,
		VideoComponent,
		AddComponent,
		DndDirective,
		CompHostDirective,
		InfoDialogComponent,
		HearAboutComponent,
		ReversePipe,
		SpinnerComponent,
		SvgIconComponent,
		FaqListComponent,
		DeferLoadDirective,
		ImageDeferLazyComponent,
		CarouselComponent,
		NewSpinnerComponent,
		CourseSelectionComponent,
		UnescapePipe,
		AdviceSectionComponent,
		MatExpansionModule,
		CurrencyFormatterPipe,
		CreditFormatterPipe,
		RoundDownFormatterPipe,
		NoDecimalCurrencyFormatterPipe
	],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MY_FORMATS
		}
	]
})
export class SharedModule { }
